import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Grid from 'shared-components/components/Grid';
import GridItem from 'shared-components/components/GridItem';
import ReactSlider from 'shared-components/components/ReactSlider';

import {
  DRAWING_ACTIONS_COPY,
} from '../../../../../constants';

import './style.scss';

const SearchAroundYouControlCircle = ({
  searchAddress,
  radius,
  radiusRange,
  startSearch,
  updateCircleRadius,
  afterUpdateCircleRadius,
  searchButtonCont,
  searchArounProps,
  actAsSmartphone,
}) => {
  const { 
    switchChannel, 
    channel, geoActive, 
    hasCircleRef: enableBtns,
  } = searchArounProps;
  const rentActive = channel === 'affitto';
  const saleActive = channel === 'vendita';
  const basBtnCls = 'csa_mapsearch__bod__over__layer__top__btn-switch';
  const baseCntCls = 'csa_mapsearch__bod__over__layer__top';
  const baseCntClsSAY = 'csa_say__top';

  const dontShowControl = geoActive !== 'granted' && actAsSmartphone;

  return (
    !dontShowControl ?
      <>
        <div className={`${baseCntCls} ${baseCntClsSAY}`}>
          <div className="csa_mapsearch__bod__over__layer__top__hdr c-bg--w mb--s">
            <img
              className="csa_mapsearch__bod__over__layer__top__hdr__icon"
              src="https://www.casa.it/external-resources/map/around.svg"
              alt={DRAWING_ACTIONS_COPY.aroundyou.desktop}
              width="100"
              height="80"
            />
            <div className="csa_mapsearch__bod__over__layer__top__hdr__claim">
              <p className="csa_mapsearch__bod__over__layer__top__hdr__claim--fst tp-s--l tp-w--l c-txt--f0">{DRAWING_ACTIONS_COPY.aroundyou.mobile}</p>
              <p className="csa_mapsearch__bod__over__layer__top__hdr__claim--sec tp-s--m tp-w--s c-txt--f0">{DRAWING_ACTIONS_COPY.aroundyou.secondary}</p>
            </div>
          </div>
        </div>

        <Grid justify="center">
          <Grid align="center" justify="center" className="csa_mapsearch__bod__over__layer__top__btns-cnt">
            <button
              disabled={!enableBtns || saleActive}
              className={`${basBtnCls}  tp-w--m tp-s--m p--s m--xs ${saleActive ? `${basBtnCls}--active` : 'c-txt--f1'}`}
              onClick={() => switchChannel('vendita')}
              type="button"
            >
              in vendita
            </button>
            <button
              disabled={!enableBtns || rentActive}
              className={`${basBtnCls}  tp-w--m tp-s--m p--s m--xs ${rentActive ? `${basBtnCls}--active` : 'c-txt--f1'}`}
              onClick={() => switchChannel('affitto')}
              type="button"
            >
              in affitto
            </button>
          </Grid>
        </Grid>
        <div className="csa_mapsearch__bod__over__layer__bot">

          <div className="csa_mapsearch__draw__radiusSlider pt--ms pr--m pb--ms pl--m c-bg--w">
            <p className="csa_mapsearch__draw__radiusSlider__text tp-s--l tp-w--s tp-a--c">
              Entro <span className="tp-w--m">{radius} m.</span> da
              {searchAddress ? ` ${searchAddress?.name}, ${searchAddress?.parent}` : '...'}
            </p>
            <ReactSlider
              value={radiusRange.indexOf(radius)}
              min={0}
              max={radiusRange.length - 1}
              className="csa_mapsearch__draw__slider"
              thumbClassName="csa_mapsearch__draw__slider__thumb"
              trackClassName="csa_mapsearch__draw__slider__track"
              onChange={value => updateCircleRadius(radiusRange[value])}
              onAfterChange={afterUpdateCircleRadius}
              disabled={!enableBtns}
            />
            <Grid className="csa_mapsearch__bod__over__layer__bot__btns" align="center">
              <GridItem className="csa_mapsearch__bod__over__layer__bot__btn" onClick={() => startSearch(channel, 'QB_MainButton')} disabled={!enableBtns}>
                <div className="csa_mapsearch__bod__over__layer__bot__btn tp-s--l tp-w--l c-txt--w c-bg--primary b--s c-br--primary tp-a--c is-clickable">
                  {searchButtonCont}
                </div>
              </GridItem>
            </Grid>
          </div>

        </div>
      </>
      :
      null
  );
};

export default SearchAroundYouControlCircle;

SearchAroundYouControlCircle.propTypes = {
  searchAddress: PropTypes.object,
  radius: PropTypes.number,
  radiusRange: PropTypes.array,
  startSearch: PropTypes.func,
  updateCircleRadius: PropTypes.func,
  afterUpdateCircleRadius: PropTypes.func,
  searchButtonCont: PropTypes.node,
  searchArounProps: PropTypes.object,
  actAsSmartphone: PropTypes.bool,
  initialized: PropTypes.bool,
};

SearchAroundYouControlCircle.defaultProps = {
  searchAddress: {},
  radius: 0,
  radiusRange: [],
  startSearch: () => { },
  updateCircleRadius: () => { },
  afterUpdateCircleRadius: () => { },
  searchButtonCont: null,
  searchArounProps: {},
  actAsSmartphone: false,
  initialized: false,

};
